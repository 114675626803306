import React from "react"
import Layout from "../Page"
import Login from "../../components/component-login"

const LoginPage = () => {
    return (
      <Layout>
        <Login/>
      </Layout>
    )
}
export default LoginPage